// =========================================
//   gnav
// =========================================
// #gnav {
//   display: none; // JSだけで非表示にすると、画面がカクつくためCSSでも対応。
// }


// =========================================
//   main
// =========================================
#main {
  img {
    width: 100%;
    height: auto;
  }
  
  .mainvisual img {
    border-top: 3px solid $color_red;
  }
}

// colum
.colum-wrap {
  display: flex;
  justify-content: space-between;

  &.col3 li {
    width: 32%;
  }
  &.colum-list {
    margin-bottom: 20px;
  }
  &.col2 li {
    &.col2-right {
      width: 32%;
    }
    &.col2-left {
      width: 66%;
    }
  }
}

// btn 
.btn {
  transform-origin: left bottom;
}


// -----------------------------------------
//   campaign
// -----------------------------------------
.campaign-ttl{
  width: 85%;
  margin: 40px auto 14px;
  .campaign-ttl-img{width: 130px!important;}
}
#campaign {
  padding: 0 60px;
  position: relative;  
  
  li {
    font-family: $font_family_go;
    width: 650px;
    
    a {
      color: $color_black;
      display: flex;

      &:link,
      &:visited {
        color: $color_black;
      }
      
      &:hover {
        color: $link_hover;
      }
      
      & > p {
        align-self: center;
        // width: 78.64%;
      }
    }
    
    figure {
      align-self: top;
      display: block;
      margin-right: 20px;
      width: 180px;
      img{width: 180px;}
    }
    
    .small {
      display: block;
      font-size: 12px;
      line-height: 1.4;
      padding-bottom: 4px;
    }
    .big{
      font-size: 20px;
      line-height: 1.3;
    }
    .blank{
      position: relative;
      &::after{
        content: "";
        background: url(/img/top/camp_blank_icon.svg) no-repeat;
        display: block;
        background-size: contain;
        width: 12px;
        height: 12px;
        position: absolute;
        right: -21px;
        bottom: 7px;
      }
    }
  }
}

.bx-wrapper {
  margin: 0 auto;
  padding-left: 2%;
}

.bx-prev {
  background: url(/img/top/arrow_l.png) 50% 50% no-repeat;
  cursor: pointer;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  width: 40px;
  height: 104px;
  position: absolute;
  top: 0;
  left: 10px;
  &:hover {
    opacity: $hover_opacity;
    transition: opacity $hover_animate;
  }
}

.bx-next {
  @extend .bx-prev;
  background: url(/img/top/arrow_r.png) 50% 50% no-repeat;
  left: auto;
  right: 10px;
}


// -----------------------------------------
//   info space
// -----------------------------------------
#info-space {
  margin-top: 50px;
}


// -----------------------------------------
//   property-search
// -----------------------------------------
#property-search {
  position: relative;
  min-width: max-content;
  margin-top: 65px;
  padding: 50px 10px;
  background-color:#E8F5EE;

  .property-search-inner {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1300px;
  }

  .lBox {
    width: 970px;
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid #ccc;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .property-search-h2 {
        display: flex;
        align-items: center;
        font-size: 24px;
        height: 40px;
    
        small {
          font-weight: 400;
          font-size: 14px;
          padding-left: 5px;
        }
      }

      .search-form {
        width: 430px;
    
        form {
          background-color: #fff;
          border:1px solid #BABABA;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          position: relative;
          width: 100%;
          height: 40px;
        }
    
        input[type="text"] {
          background: transparent;
          display: block;
          width: calc(100% - 37px);
          height: 38px;
          padding: 0 20px;
          border: none;
          font-size: 14px;
          line-height: 38px;
        }
    
        button {
          width: 37px;
          height: 40px;
          border: none;
          cursor: pointer;
          background: transparent url(/img/top/button_search.png) center 7px no-repeat;
          background-size: 22px auto;
          overflow: hidden;
          text-indent: -9999px;
          padding: 0;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
  
      .box {
         width: 460px;
         padding: 20px;
         background-color: #DDE9E3;
         
        .property-search-h3 {
          display: flex;
          align-items: center;
          font-size: 20px;
          margin-bottom: 10px;
      
          small {
            font-weight: 400;
            font-size: 12px;
            padding-left: 5px;
          }
        }

        .buyLink {
          display: block;
          color: #006E3D;
          margin-bottom: 5px;
        }
      }
    }
  }

  .search-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
        
    .li {
      width: 49%;
      margin-bottom: 5px;

      &.w100 {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .link {
        background-color: #fff;
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        color: #333;
        display: block;
        font-size: 18px;
        font-weight: bold;
        padding: 12px 10px 12px 40px;
        position: relative;

        &:hover {
          color: #c57800;
          transition: color 0.3s ease;
        }

        &::before {
          border-style: solid;
          border-width: 5px 0 5px 8.7px;
          border-color: transparent transparent transparent #006e3d;
          content: "";
          display: block;
          height: 0;
          width: 0;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
        }
      }
    }
  }

  .rBox {
    width: 300px;
    flex-shrink: 0;

    .box {
      margin-bottom: 20px;

      .h3 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      .imgBox {
        height: 110px;

        .link {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .text {
            position: absolute;
            text-align: center;
            
            .small {
              font-size: 14px;
              color: white;
              line-height: 1.5;
              display: block;
              font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Noto Sans Japanese", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
            }
            .large {
              font-size: 24px;
              color: white;
              line-height: 1.5;
              display: block;
            }
          }
        }
      }
    }
  }
}

// -----------------------------------------
//   contents list
// -----------------------------------------
#contents-list {
  margin-top: 90px;
  
  li {
    position: relative;

    a {
      cursor: pointer;
      
      .list-txt img {
        width: auto;
      }
      &:hover {
        figure img {
          opacity: $hover_opacity;
          transition: opacity $hover_animate;
        }

        .list-txt img {
          opacity: 1;
          width: auto;
        }

        .btn {
          background-color: #ee4d5a;
          transition: background-color 0.3s ease;
        }
      }
    }
  }
  
  .txt-wrap {
    margin-top: -5px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translateY(-50%) translateX(-50%);
    // width: 71.29%;
    
    .btn {
      margin-top: -9px;
      &:hover {
        opacity: 1;
      }
    }
  }
}

#sdgs {
  margin-top: 50px;
}

.bg-blue {
  background-color: #DFEFF2;
  padding: 10px;
  margin-top: 50px;
  #contents-list {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}


// -----------------------------------------
//   company
// -----------------------------------------
#company {
  background-color: #f9f9f9;
  margin-top: 90px;
  
  .inner-max {
    display: flex;
    align-items: center;
  }
  
  figure {
    width: 58.75%;
  }
  
  .txt-wrap {
    padding: 20px;
    width: 41.25%;
    
    .txt-inner {
      margin: 0 auto;
      width: 375px;
    }
  }
  
  a:hover .btn {
    background-color: #626262;
  }
  
  .btn-wrap {
    margin-top: 40px;
    text-align: right;
    .btn {
      transform-origin: right top;
    }
  }
}


// -----------------------------------------
//   newarrivals
// -----------------------------------------
#newarrivals {
  margin-top: 90px;
  position: relative;

  h2 {
    margin-right: 25px;
    width: 187px;
  }
  
  ul {
    margin-top: 20px;
  }

  li {
    position: relative;
  }
  
  .description {
    background-color: rgba(0, 110, 60, .8);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.375vw;
    padding: 10px;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    
    @include min-screen(1620px) {
      & {
        font-size: 22px;
      }
    }
    
    @include max-screen(1044px) {
      & {
        font-size: 14px;
      }
    }
    
    &-txt {
      span {
        display: block;
        line-height: 1.4;
        @include arrow-left(4px, #fff);
        padding-left: 12px;
      }
    }
  }
  
  .btn-list {
    position: absolute;
    top: 40px;
    left: 222px;
    
    .btn {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}


// -----------------------------------------
//   newsrelease
// -----------------------------------------
#newsrelease {
  margin-top: #{26 + 90}px;

  .newsrelease-inner {
    border: 1px dotted #666;
    padding: 0 30px 45px 6%;
    margin: 0 50px;
    position: relative;
  }

  .ttl-wrap {
    background-color: #fff;
    margin: -26px 0 0 -4.8%;
    padding-left: 35px;
    width: 620px;
  }

  h2 {
    width: 333px;
  }
  
  .newsrelease-list {
    margin-top: 50px;
    height: 245px;
    overflow-y: scroll;

    ul {
      font-family: $font_family_go;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin-bottom: 25px;
      width: 50%;
      
      &.new {
        dt {
          position: relative;
          
          &::before {
            color: $color_red;
            content: "NEW";
            font-size: 12px;
            display: block;
            position: absolute;
            top: 0;
            left: -35px;
          }
        }
      }
    }
    
    dl {
      display: flex;
      align-items: flex-start;
      padding: 0 40px;
      
      dt {
        color: #aaa;
        margin-right: 20px;
      }
      
      dd {
        word-break: break-all;
      }
    }
  }
  
  .btn-list {
    position: absolute;
    top: -12px;
    left: 433px;
    
    a {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}


// -----------------------------------------
//   sns
// -----------------------------------------
#sns {
  background-color: #f9f9f9;
  margin-top: 90px;
  padding: 40px 0 30px;

  ul {
    display: flex;
    justify-content: space-between;
    width: 635px;
  }
}


// -----------------------------------------
//   related link
// -----------------------------------------
#related-link {
  background-color: #f9f9f9;
  padding-bottom: 70px;
  
  ul {
    font-family: $font_family_go;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    &::before,
    &::after {
      content:"";
      display: block;
    }
    
    &::before,
    &::after,
    li {
      width:237px;
    }
    
    &::before {
      order:1;
    }
    
    li {
      margin-bottom: 15px;
      text-align: center;
      
      a {
        color: $color_gray;
        &:link,
        &:visited {
          @extend a;
        }
        
        &:hover {
          color: $link_hover;
        }
      }
      
      p {
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }
}
