@charset "UTF-8";
@import "../common/css/_variables";
@import "../common/css/_mixin";

// pc
@import "_top_pc";

@include max-screen {
  // sp
  @import "_top_sp";
}