// =========================================
//   gnav
// =========================================
#gnav {
  display: block;
}

// =========================================
//   main
// =========================================
#main {
  font-size: 12px;
  
  img {
    width: 100%;
    height: auto;
  }
  
  .mainbisual img {
    border-top: 2px solid $color_red;
  }
}

// colum
.colum-wrap {
  display: block;
  justify-content: space-between;

  &.col3 li {
    margin-bottom: 10px;
    width: 100%;
  }
  &.col2 li {
    margin-bottom: 10px;
    &.col2-left {
      width: 100%;
    }
    &.col2-right {
      width: 100%;
    }
  }

}

// btn 
.btn {
  transform-origin: left bottom;
}


// -----------------------------------------
//   campaign
// -----------------------------------------
.campaign-ttl{
  width: 100%;
  margin: 30px auto 23px;
  text-align: center;
  .campaign-ttl-img{width: 104px!important;}
}
#campaign {
  margin-top: 15px;
  padding: 0 25px;
  position: relative;

  li {
    font-family: $font_family_go;
    @include width-sp(674);
    
    a {
      color: $color_black;
      display: flex;
      align-items: center;

      &:link,
      &:visited {
        color: $color_black;
      }
      
      &:hover {
        color: $link_hover;
      }
    }
    
    figure {
      display: block;
      margin-right: 10px;
      width: 110px;
      img{width: 110px;}
    }
    
    .small {
      display: block;
      font-size: 10px;
      line-height: 1.4;
      font-weight: normal;
    }
    .big{
      font-size: 16px;
      line-height: 1.3;
    }

    .blank{
      position: relative;      
      &::after{
        content: "";
        background: url(/img/top/camp_blank_icon.svg) no-repeat;
        display: block;
        background-size: contain;
        width: 11px;
        height: 11px;
        position: absolute;
        right: -20px;
        bottom: 5px;
      }
    }
  }
}

.bx-wrapper {
  margin: 0 auto;
}

.bx-prev {
  background: url(/img/top/arrow_l.png) 50% 50% no-repeat;
  background-size: 9px auto;
  cursor: pointer;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  &:hover {
    opacity: $hover_opacity;
    transition: opacity $hover_animate;
  }
}

.bx-next {
  background: url(/img/top/arrow_r.png) 50% 50% no-repeat;
  background-size: 9px auto;
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
}


// -----------------------------------------
//   info space
// -----------------------------------------
#info-space {
  margin-top: 20px;
}


// -----------------------------------------
//   property-search
// -----------------------------------------
#property-search {
  background-color:#E8F5EE;
  padding: 20px 10px;
  min-width: auto;

  .property-search-inner {
    display: block;
    margin: 0 auto;
  }

  .lBox {
    width: 100%;
    border-right: none;
    padding-right: 0px;


    .top {
      display: block;
      margin-bottom: 15px;

      .property-search-h2 {
        display: flex;
        align-items: center;
        font-size: 18px;
    
        small {
          font-weight: 400;
          font-size: 12px;
          padding-left: 5px;
        }
      }

      .search-form {
        width: 100%;
        margin-top: 15px;
    
        form {
          width: 100%;
          height: 44px;
        }
    
        input[type="text"] {
          font-size: 16px;
          width: calc(100% - 50px);
          height: 42px;
          padding: 0 10px;
          line-height: 42px;
        }
    
        button {
          width: 50px;
          height: 44px;
          background: transparent url(/img/top/button_search.png) center 10px no-repeat;
          background-size: 20px auto;
        }
      }
    }

    .bottom {
      display: block;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;

      .box {
        width: 100%;
         padding: 10px;
         background-color: #DDE9E3;
         margin-bottom: 10px;
         
        .property-search-h3 {
          display: flex;
          align-items: center;
          font-size: 16px;
          margin-bottom: 5px;
      
          small {
            font-weight: 400;
            font-size: 12px;
            padding-left: 5px;
          }
        }

        .buyLink {
          display: block;
          color: #006E3D;
          margin-bottom: 5px;
          font-size: 12px;
        }
      }
    }
  }

  .search-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .li {
      width: 32.5%;

      &.w100 {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .link {
        background-color: #fff;
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        color: #333;
        display: block;
        font-size: 18px;
        font-weight: bold;
        padding: 12px 10px 12px 35px;
        position: relative;

        &::before {
          border-style: solid;
          border-width: 5px 0 5px 8.7px;
          border-color: transparent transparent transparent #006e3d;
          content: "";
          display: block;
          height: 0;
          width: 0;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
        }
      }
    }

    &.sp_colum3 {
      .li {
        &:last-child ,&:nth-last-child(2) ,&:nth-last-child(3) {
          margin-bottom: 0;
        }
      }
    }
  }

  .rBox {
    width: 100%;
    margin-top: 25px;

    .box {
      margin-bottom: 10px;

      .h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .imgBox {
        height: 70px;

        .link {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          .img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .text {
            position: absolute;
            text-align: center;
            
            .small {
              font-size: 12px;
              color: white;
              line-height: 1.5;
              display: block;
              font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Noto Sans Japanese", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
            }
            .large {
              font-size: 18px;
              color: white;
              line-height: 1.5;
              display: block;
            }
          }
        }
      }
    }
  }
}


// -----------------------------------------
//   contents list
// -----------------------------------------
#contents-list {
  margin-top: 32px;
  
  li {
    position: relative;

    a {
      cursor: pointer;
      
      &:hover {
        figure img {
          opacity: $hover_opacity;
          transition: opacity $hover_animate;
        }

        .list-txt img {
          opacity: 1;
          width: auto;
        }

        .btn {
          background-color: #ee4d5a;
          transition: background-color 0.3s ease;
        }
      }
    }
  }
  
  .txt-wrap {
    margin-top: -5px;
    position: absolute;
    top: 50%;
    left: 0;
    text-align: center;
    transform: translateY(-50%);
    width: 100%;
    
    .btn {
      margin-top: 15px;
      padding-left: 46px;
      padding-right: 46px;
      
      &:hover {
        opacity: 1;
      }
    }
  }
}

#sdgs {
  margin-top: 20px;
}

.bg-blue {
  padding: 1px;
  margin-top: 30px;
  #contents-list {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}


// -----------------------------------------
//   company
// -----------------------------------------
#company {
  background-color: #f9f9f9;
  margin-top: 40px;
  
  .inner-max {
    display: block;
    position: relative;
  }
  
  figure {
    width: 100%;
  }
  
  .txt-wrap {
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    
    .txt-inner {
      margin: 0 auto;
      @include width-sp(630);
    }
  }

  .btn-wrap {
    margin: 10px auto 0;
    text-align: center;
    
    a {
      box-shadow: 0 0 20px 12px #fff;
    }
  }
}


// -----------------------------------------
//   newarrivals
// -----------------------------------------
#newarrivals {
  margin-top: 40px;
  position: relative;

  h2 {
    margin: 0 auto;
    @include width-sp(275);
  }
  
  ul {
    margin-top: 20px;
    
    li {
      margin-bottom: 0;
      
      &:first-child {
        border-top: 1px dotted #cecece;
      }
      
      a {
        background-color: #f5f5f5;
        border-bottom: 1px dotted #cecece;
        display: flex;
        align-items: center;
        padding: 10px $sp_contents_padding;
      }
    }
  }
  
  figure {
    width: 42%;
  }
  
  .description {
    background-color: transparent;
    color: $color_black;
    display: block;
    font-size: 12px;
    padding: 15px 0 15px 15px;
    position: static;
    bottom: auto;
    text-align: left;
    width: 58%;

    &-txt {
      span {
        line-height: 1.78;
        padding-left: 0;

        &::before {
          content: none;
        }
      }
    }

    .btn {
      margin-top: 10px;
      padding: 10px 0;
      text-align: center;
      width: 90%;

      span {
        @include arrow-left(4px, #fff);
        display: inline-block;
      }
    }
  }
  
  .btn-list {
    position: static;
    top: auto;
    left: auto;
    margin-top: 15px;
    text-align: center;

    .btn {
      padding-left: 26px;
      padding-right: 26px;
    }
  }
}


// -----------------------------------------
//   newsrelease
// -----------------------------------------
#newsrelease {
  margin-top: 40px;

  .newsrelease-inner {
    border: 0 none;
    padding: 0;
    margin: 0;
    position: relative;
  }

  .ttl-wrap {
    background-color: transparent;
    margin: 0;
    padding-left: 0;
    width: auto;
  }

  h2 {
    @include width-sp(445);
    margin: 0 auto;
  }
  
  .newsrelease-list {
    margin: 20px 0;
    height: 380px;
    overflow-y: scroll;

    ul {
      font-family: $font_family_go;
      display: block;
    }

    li {
      border-bottom: 1px dotted #cecece;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      width: auto;
      
      &:first-child {
        border-top: 1px dotted #cecece;
      }
      
      &.new {
        dl {
          position: relative;
          
          &::before {
            content: none;
          }
          
          dt {
            &::after {
              color: $color_red;
              content: "NEW";
              display: inline-block;
              margin-left: 7px;
            }
          }
        }
      }
    }
    
    dl {
      display: block;
      padding: 0;
      
      dt {
        font-size: 10px;
        color: #aaa;
        margin-right: 20px;
      }
      
      dd {
        word-break: break-all;
        
        a {
          color: $color_black;
        }
      }
    }
  }
  
  .btn-list {
    position: static;
    top: auto;
    left: auto;
    text-align: center;
    
    .btn {
      padding-left: 26px;
      padding-right: 26px;
    }
  }
}


// -----------------------------------------
//   sns
// -----------------------------------------
#sns {
  background-color: #f9f9f9;
  margin-top: 30px;
  padding: 20px 0;

  ul {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    width: 100%;
    max-width: 100%;
  }
  
  li {
    width: 10.5%;
  }
}


// -----------------------------------------
//   related link
// -----------------------------------------
#related-link {
  background-color: #f9f9f9;
  padding-bottom: 0;
  
  .inner {
    padding: 0;
  }
  
  h2 {
    background-color: #b01723;
    color: #fff;
    padding: 15px 0;
    text-align: center;
    
    span {
      padding-left: 15px;
      position: relative;
      
      &::before {
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(45deg);
        transition: transform $hover_animate;
      }
    }
    
    &.on {
      span {
        &::before {
          top: 4px;
          transform: rotate(225deg);
        }
      }
    }
  }
  
  ul {
    font-family: $font_family_go;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px $sp_contents_padding;
    
    &::before {
      content: none;
    }
    
    &::after {
      content:"";
      display: block;
    }
    
    &::after,
    li {
      width: 31.12%;
    }

    li {
      margin-bottom: 5px;
      text-align: center;
      
      a {
        color: $color_gray;
        
        &:hover {
          color: $link_hover;
        }
      }
      
      p {
        font-size: 10px;
        margin-top: 2px;
      }
    }
  }
}
